import { ref, watch } from '@vue/composition-api'
import {
  isEqual, isNil, omitBy, transform,
} from 'lodash-es'
import cloneDeep from 'lodash/cloneDeep'

import { apiBank } from '@/api'

import useToast from '@useToast'

export default function useSepayHandle() {
  const { toastSuccess, toastError } = useToast()
  const filterBlank = {
    size: 10,
    page: 1,
    sortDirection: 'DESC',
    isSortDirDesc: false,
    sortBy: 'shortName',
    searchText: null,
  }
  // Loading
  const loading = ref(true)
  const loadingForm = ref(true)

  const bank = ref({})
  const banks = ref([])
  const total = ref(0)
  const filter = ref(cloneDeep(filterBlank))
  const bankForm = ref({})
  const auditLogs = ref({})

  async function fetchBanks(isClearFilter) {
    if (isClearFilter) {
      filter.value = cloneDeep(filterBlank)
    }
    try {
      loading.value = true
      filter.value.sortDirection = filter.value.isSortDirDesc ? 'DESC' : 'ASC'

      const params = omitBy(
        filter.value,
        value => isNil(value) || value === '',
      )
      delete params.isSortDirDesc
      const { data } = await apiBank.getBanks(params)
      banks.value = data.items
      total.value = data.total
      loading.value = false
    } catch (error) {
      toastError(error)
    }
  }

  async function fetchBank(id) {
    try {
      const { data } = await apiBank.getBankById(id)
      bank.value = cloneDeep(data)
      bankForm.value = cloneDeep(data)
      loadingForm.value = false
      return data
    } catch (error) {
      toastError(error)
      return null
    }
  }

  async function updateBank(id, callback) {
    try {
      loadingForm.value = true
      const temp = transform(bankForm.value, (result, value, key) => {
        if (!isEqual(value, bank.value[key])) {
          result[key] = value
        }
      })
      if (Object.keys(temp).length > 0 && temp.constructor === Object) {
        await apiBank.update(id, temp)
        fetchBanks()
        toastSuccess('bank.updateSuccess')
      }
    } catch (error) {
      toastError(error)
    } finally {
      loadingForm.value = false
      if (callback && typeof callback === 'function') {
        callback()
      }
    }
  }

  async function getLogsById(id) {
    try {
      const { data } = await apiBank.getLogsById(id)
      auditLogs.value = data
      return data
    } catch (error) {
      toastError(error)
      return null
    }
  }

  watch(
    filter,
    () => {
      fetchBanks()
    },
    { deep: true },
  )

  return {
    // Const
    loading,
    loadingForm,
    bank,
    banks,
    total,
    filter,
    bankForm,

    // Functions
    fetchBanks,
    fetchBank,
    updateBank,
    getLogsById,
  }
}
